import 'bootstrap';
import 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/styles/main.scss';

$(document).ready(() => {
  AOS.init({mirror: false});

  $('#scroll-arrow').on('click', () => {
    const contact = $('#contact');
    $('html, body').animate({scrollTop: contact.offset().top}, 500);
  });

  $('#contact-subject a').each((_idx, elem) => {
    const link = $(elem);
    link.on('click', () => {
      $("#contact-subject-value").val(link.text());
      $('#contact-subject-value-text').text(link.text());
    });
  });
  const contactForm = $('#contact-form');

  const validateField = (id, formId = "") => {
    const elem = $(id);
    const formElem = $(formId || id);
    if (!elem.val()) {
      formElem.addClass("is-invalid").removeClass("border-primary");
    }
  };

  contactForm.on('submit', (event) => {
    event.preventDefault();

    const fieldIds = [["#contact-subject-value", "#dropdownContactSubjectButton"], ["#contact-mail"], ["#contact-name"], ["#contact-phone"], ["#contact-message"]];
    if (!contactForm[0].checkValidity()) {
      for (let i = 0; i < fieldIds.length; i++) {
        validateField(...fieldIds[i]);
      }
    } else {
      fetch("https://api.hanja.tech/mail", {
        method: 'PUT',
        headers: {
          'Content-Type': "application/json"
        },
        body: JSON.stringify({
          subject: $("#contact-subject-value").val(),
          email: $("#contact-mail").val(),
          fullName: $("#contact-name").val(),
          phone: $("#contact-phone").val(),
          company: $("#contact-company").val(),
          message: $("#contact-message").val()
        })
      }).then(response => {
        if (response.ok) {
          console.log('mail sent', response);
        }
      });
    }
  });
});



